<ngx-spinner [fullScreen]="false" type="cog" size="large" [name]="DOWNLOAD_SPINNER">
  <div class="spinner-container">
    <p class="download-spinner">Proposal downloading. Please wait.</p>
  </div>
</ngx-spinner>
<form [formGroup]="form">
  <mat-dialog-content>   
    <div class="textContent">To continue with Proposal generation, please click submit.</div>       
  </mat-dialog-content>
  
  <mat-dialog-actions class="dialog-actions">
    <mat-checkbox class="checkbox-left" formControlName="selectedOption" [(ngModel)]="includeCAD" (change)="onIncludeCADChange($event)">Include CAD Drawings</mat-checkbox>
    
    <div class="button-container">
      <button mat-flat-button color="accent" type="button" class="cpq-button-text btn-submit-quote" (click)="onSubmit()">Submit</button>
      <button mat-flat-button color="accent" type="button" class="cpq-button-text btn-submit-quote" (click)="onCancel()">Cancel</button>
    </div>
  </mat-dialog-actions>
</form>