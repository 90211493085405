import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@cpq-environments/environment';
import { Observable } from 'rxjs';

const Salesforce_API = {
  SALESFORCE: 'salesforce',
  OPPORTUNITY: 'opportunity',
  LEAD: 'lead',
  OBJECT: 'object',
  CURRENT_CONTACT: 'current-contact',
  ACCOUNT: 'account',
  ATTACHMENT: 'upload_attachment'
};

export interface SfdcOpportunity {
  Name: string;
  quoteNumber?: string;
  StageName?: string;
  CloseDate?: Date;
  AccountId?: string;
  accountNumber?: string;

}

export enum SfdcLeadSource {
  CPQ = 'CPQ'
}

export enum sfdcObjectType {
  LEAD = 'Lead',
  OPPORTUNITY = 'Opportunity',
  TASK = 'Task',
  CONTACT = 'Contact'
}

@Injectable(
  { providedIn: 'root' }
)
export class SalesforceProxyService {
  constructor(
    private http: HttpClient) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  createOpportunity(payload): Observable<SalesforceResult> {
    return this.http.post(`${this.backendUrl}/salesforce/opportunity`, payload);
  }

  // createOpportunity(payload: SfdcOpportunity): Observable<SalesforceResult> {
  //   return this.createSfdcObject(sfdcObjectType.OPPORTUNITY, payload);
  // }

  createSfdcObject(sfdcObjectType: sfdcObjectType, payload: any): Observable<SalesforceResult> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType);
    return this.http.post(url, payload);
  }

  getSfdcAccountById(sfdcAccountId: string): Observable<any> {
    const url = this.salesforceUrl(Salesforce_API.ACCOUNT, sfdcAccountId);
    return this.http.get(url);
  }

  getSfdcObjectById(sfdcObjectType: sfdcObjectType, objectId: string): Observable<any> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType, objectId);
    return this.http.get(url);
  }
  updateSfdcObjectById(sfdcObjectType: sfdcObjectType, objectId: string, payload): Observable<SalesforceResult> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType, objectId);
    return this.http.put(url, payload);
  }

  updateOpportunity(opportunityId: string, payload): Observable<SalesforceResult> {
    const url = this.salesforceUrl(sfdcObjectType.OPPORTUNITY, opportunityId);
    return this.http.put(url, payload);
  }

  /**
   * Uploads any attachment to Salesforce Object
   * @param crmObjectId - Id of the Salesforce Object
   * @param file - Blod data as Form data
   * @returns Id of the salesforce attachment
   */
  uploadAttachmentToSfdc(crmObjectId: string, file): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url = this.salesforceUrl(Salesforce_API.OBJECT, crmObjectId, Salesforce_API.ATTACHMENT);
    return this.http.post<any>(url, formData, { withCredentials: true });
  }

  salesforceUrl(...args: string[]): string {
    let url = `${this.backendUrl}/${Salesforce_API.SALESFORCE}`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += '/' + args[i];
      }
    }

    return url;
  }

}

export interface SalesforceResult {
  errors?: Array<any>;
  id?: string;
  externalId?: string;
}
