import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Opportunity, Quote } from "@cpq-app/models/twg-interfaces";
import { CartService, CpqObjects } from "@cpq-app/services/cart.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription, of } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";

@Component({
  selector: "app-edit-quote",
  templateUrl: "./edit-quote.component.html",
  styleUrls: ["./edit-quote.component.scss"],
})
export class EditQuoteComponent implements OnInit, OnDestroy {
  quoteForm: FormGroup;
  LOGIN_SPINNER = "login";
  @Input() quote: any;
  @Input() type: any;
  disable = false
  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
  ) { }

  ngOnInit(): void {
    this.initialFormData();
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp("\\S");
    this.quoteForm = this.formBuilder.group({
      Name: [
        null,
        [
          Validators.required,
          Validators.pattern(nonWhiteSpaceRegExp),
          Validators.maxLength(120),
        ],
      ],
      Note: [""],
      IsPrimary: [""],
    });
    this.setData();
  }

  setData() {
    if (this.quote) {
      if (this.quote?.IsPrimary) {
        this.quoteForm.get('IsPrimary').disable();
      }
      this.quoteForm.controls.Name.setValue(this.quote?.Name ?? 'New Quote');
      this.quoteForm.controls.Note.setValue(this.quote?.Note);
      this.quoteForm.controls.IsPrimary.setValue(
        Boolean(this.quote?.IsPrimary)
      );
    }
  }

  updateDetails() {
    this.spinner.show();
    if (this.type !== 'create') {
      const data = {
        Name: this.quoteForm.value.Name,
        Note: this.quoteForm.value.Note,
        IsPrimary: Number(this.quoteForm.value.IsPrimary),
      };
      const quoteChanged = data.Name !== this.quote.Name || data.Note !== this.quote.Note || data.IsPrimary !== this.quote.IsPrimary;
      if (quoteChanged) {
        const subscription$ = this.cartService
          .updateObjectById(CpqObjects.Quote, this.quote.Id, data).pipe(
            switchMap((x: Quote) => {
              if (Boolean(this.quoteForm.value.IsPrimary) !== Boolean(this.quote.IsPrimary)) {
                console.log('primary key update');
                return this.cartService
                  .updateObjectById(CpqObjects.Opportunities, this.quote.OpportunityId, {
                    PrimaryQuoteId: this.quote.Id,
                  })
              } else {
                console.log('No primary key update');
                return of(x);
              }
            })
          ).subscribe(
            (res: Opportunity | Quote) => {
              console.log('res', res);
              this.spinner.hide();
              this.modal.close({ success: true, data: res });
            },
            err => {
              this.spinner.hide();
            }
          );
        this.subscriptions.add(subscription$);
      }
    }
    else {
      const dataValue = {
        Name: this.quoteForm.value.Name,
        Note: this.quoteForm.value.Note,
        IsPrimary: Number(this.quoteForm.value.IsPrimary),
      };
      this.modal.close({ success: true, data: dataValue });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
