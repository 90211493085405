import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService, CpqAccount, CpqObjects, userRole, CPQ_EXPORT_STATUS } from '@cpq-app/services/cart.service';
import { SalesforceProxyService, sfdcObjectType, SfdcOpportunity } from '@cpq-app/services/salesforce.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription, of, iif } from 'rxjs';
import { delay, switchMap, map, concatMap, take } from 'rxjs/operators';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Opportunity } from '@cpq-app/models/twg-interfaces';
import { DocGenService } from '../doc-gen-modal/doc-gen.service';
import { ProductService, cadParamGroups } from '@cpq-app/services/product.service';
const FPX_CREATION_DELAY = 2000; // mS
const CLOSING_EXTENSION_DAYS = 30;
import { PDFDocument } from 'pdf-lib';

@Component({
  selector: 'app-submit-quote-model',
  templateUrl: './submit-quote-model.component.html',
  styleUrls: ['./submit-quote-model.component.scss'],
})
export class SubmitQuoteModelComponent implements OnInit, AfterViewChecked {
  SUBMIT_SPINNER = 'submitSpinner';
  submitQuoteForm: FormGroup;
  quoteName: string;
  quoteNote: string;
  availableAccounts = [];
  selectedAccount;
  needsSfdcOpp: boolean;
  commonSubscription = new Subscription();
  notGuest: boolean;
  internalUser;

  salesForceOpportunityId;
  subscription: Subscription;
  opportunityDetails;
  isAccountAssociationChange = false;

  constructor(
    private dialogRef: MatDialogRef<SubmitQuoteModelComponent>,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private salesforce: SalesforceProxyService,
    private toastr: ToastrService,
    private guestService: GuestConfigService,
    private changeDetector: ChangeDetectorRef,
    private docGenService: DocGenService,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.submitQuoteForm = new FormGroup({
      accountSelection: new FormControl('', [Validators.required]), // to do Validators.required
      quoteName: new FormControl('', [Validators.required, Validators.maxLength(120)]),
      quoteNote: new FormControl('', [Validators.maxLength(2000)]),
    });
    if (sessionStorage.getItem('userRole') === userRole.internal) {
      this.internalUser = true;
    }
    this.notGuest = !this.guestService.getGuestUserDetails()?.isGuest;

    const parallelProcess = [];

    if (this.notGuest) {
      parallelProcess.push(this.cartService.getCpqObjects(CpqObjects.Accounts).pipe(map((accounts:any[]) => this.availableAccounts = accounts.filter(acc => acc.ExternalId !=='')))); // remove filter if we need account number mapping when there is no external Id
    }

    parallelProcess.push(this.cartService.getCpqObjects(CpqObjects.Opportunities, this.data.opportunityId).pipe(map((opps: Opportunity) => {
      if (opps) {
        const opp = this.opportunityDetails = opps;
        this.needsSfdcOpp = !opp.CrmId; // to fix ExternalId
        // this.selectedAccount = opp?.AccountId;
      }
    })));
    
    
    forkJoin(parallelProcess).subscribe(() => {
      this.selectedAccount = this.availableAccounts.find(ele => ele.Id === this.opportunityDetails.AccountId);
      if (!this.selectedAccount && this.availableAccounts?.length > 0) {
        this.selectedAccount = this.availableAccounts[0];
      }

      if (this.notGuest && !this.selectedAccount && this.availableAccounts.length === 1) {
        console.log(`Single account logic triggered`);
        this.selectedAccount = this.availableAccounts[0];
        // this.onAssociatedAccountChange();
      }
    }, err => {
      // FIXME Add error handling in submit quote init
      console.error(`Init of submit modal failed`, err);
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  private conditionallyCreateSfdcOpp(): Observable<any> {
    // FIXME: Make sure that the init has resolved this value
    let externalId = sessionStorage.getItem('ExternalId');
    if(externalId === 'null' || externalId === 'undefined') {
      externalId = this.opportunityDetails.CrmId;
    }
    const userType = sessionStorage.getItem('userType');
    let account = this.submitQuoteForm.value.accountSelection ? this.submitQuoteForm.value.accountSelection : '';
    let payload: SfdcOpportunity = {
      Name: this.data.quoteName,
    }
    if(account.ExternalId !== '') {
      payload.AccountId = account?.ExternalId;
    } else {
      payload.accountNumber = account?.AccountNumber;
    }
    if (this.needsSfdcOpp) {
      payload.quoteNumber = this.data.quoteNumber;
      return this.salesforce.createOpportunity(payload).pipe(
        switchMap(result => {
          // this.selectedAccount.ExternalId = result?.id;
          //Below logic is to Link the CPQ Opportunity with Salesforce Opportunity
          this.salesForceOpportunityId = result.id;
          this.productService.salesForceOpportunityId = result.id;
          return this.cartService.updateObjectById(CpqObjects.Opportunity, this.data.opportunityId, {
            CrmId: result.id, // to be fixed ExternalId
            Name: this.data.quoteName
          });
        }),
      );
    } else if (externalId) {
      // call sfdc opportunity update api
      
      return this.salesforce.updateOpportunity(externalId, payload).pipe(
        switchMap(result => {
          // this.selectedAccount.ExternalId = result?.id;
          // We do not need to call this instead we can export_tosfdc API
          this.salesForceOpportunityId = result.id;
          this.productService.salesForceOpportunityId = result.id;
          return this.cartService.updateObjectById(CpqObjects.Opportunity, this.data.opportunityId, {
            Name: this.data.quoteName
          });
        }),
      );
    }

    return of(false);
  }

  compareObjects(obj1: CpqAccount, obj2: CpqAccount): boolean {
    return obj1?.Id === obj2?.Id;
  }

  displayFn(account?: any): string | undefined {
    return account ? `${account.Name} ${account.AccountNumber}` : undefined;
  }

  submitQuote() {
    if (this.submitQuoteForm.valid) {
      this.spinner.show(this.SUBMIT_SPINNER);

      // const workflowTask = this.cartService.startWorkflow(this.data.quoteId); // to do
      const exportTask = this.cartService.exportToCrm(this.data.opportunityId);
      this.subscription = forkJoin([
        // Update the Quote
        this.cartService.updateObjectById(CpqObjects.Quote, this.data.quoteId, {
          Name: this.data.quoteName,
          Note: this.data.quoteNote,
        }),
        // Proactively update the products
        // this.cartService.updateProducts([this.data.quoteId]), // to re check
        // If the FPX Opp doesn't have an ExternalId, create the SFDC Opp and update FPX Opp
        this.conditionallyCreateSfdcOpp(),
        this.getProposalWithCADData()
      ]).
      // pipe(
        // Pause to give the FPX database a chance to catch-up
        // delay(FPX_CREATION_DELAY),
        // switchMap(x => iif(() => this.notGuest, workflowTask, of(false))),
        pipe(
          switchMap( x=>exportTask),
          concatMap(() => this.productService.getProposalWithDocFile().pipe(take(1))),
          concatMap(result => 
            this.salesforce.uploadAttachmentToSfdc(this.salesForceOpportunityId, result)
          )
        
      ).subscribe({
        next: () => {
          this.spinner.show(this.SUBMIT_SPINNER);
          this.close(true);
          this.productService.isCadGenCompleted.next(false)
        },
        error: err => {
          this.spinner.hide(this.SUBMIT_SPINNER);
          this.toastr.error(`We're Sorry; something went wrong while submitting your quote.`, `Error`, { timeOut: 5000 });
        },
        complete: () => {
          this.spinner.hide(this.SUBMIT_SPINNER);
        }
      });

    } else {
      this.spinner.hide(this.SUBMIT_SPINNER);
      this.toastr.warning('Please check what you entered and correct any mistakes');
    }
  }

  close(success = false) {
    this.spinner.hide(this.SUBMIT_SPINNER);
    this.dialogRef.close({ success,  isAccountAssociationChange: this.isAccountAssociationChange});
  }

  validateMaxLength(fieldName: string): boolean {
    const control = this.submitQuoteForm.controls[fieldName];
    return control?.errors?.maxlength || false;
  }

  validateRequiredField(fieldName: string): boolean {
    const control = this.submitQuoteForm.controls[fieldName];
    return control?.errors?.required || false;
  }

  onAssociatedAccountChange() {
    const updateSubscription = this.cartService.updateObjectById(CpqObjects.Opportunity,this.data.opportunityId, {
      AccountId: this.selectedAccount.Id,
    }).subscribe((res) => {
      this.cartService.updateCart.next(this.selectedAccount.Id);
      // Do we need to take any action?
      this.isAccountAssociationChange = true;
    });

    this.commonSubscription.add(updateSubscription);
  }

  getProposalWithCADData(): Observable<any> {
    this.spinner.show(this.SUBMIT_SPINNER);
    this.productService.quoteProposalUrl(this.data.quoteId);
    this.productService.generateProposalsWithCadDrawings(this.data.quoteId, this.data?.fileName,true);

      
      return of(false);
  }

  ngOndestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
