import { Injectable } from '@angular/core';
import { MSALService } from '../services/msal.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private msalService: MSALService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const ExternalId = route.params['sfdcOpportunityId'];
    if(ExternalId && ExternalId !== 'Logout' && ExternalId !== 'null') {
      sessionStorage.setItem('ExternalId', ExternalId);
    }
    if (this.msalService.isOnline() || (this.msalService.accessToken && this.msalService.accessToken !== '')) { // to do
      localStorage.setItem('logged_in', 'true');
      const oppId = sessionStorage.getItem('ExternalId');
      if(state.url === '/' && oppId && oppId !== 'undefined' && oppId !== 'null') {
        this.router.navigateByUrl(`opportunity/${oppId}`);
      }
      return true;
    }
    this.msalService.login();
    return false;
  }
}
