import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-thank-you-modal',
  templateUrl: './thank-you-modal.component.html',
  styleUrls: ['./thank-you-modal.component.scss']
})
export class ThankYouModalComponent implements OnInit {
  DOWNLOAD_SPINNER = 'downloadSpinner';

  constructor(
    private dialogRef: MatDialogRef<ThankYouModalComponent>,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show(this.DOWNLOAD_SPINNER);
  }

  done(): void {
    if(sessionStorage.getItem('salesforceToken')) {
      window.parent.postMessage('closeIframe', '*');
    }
    this.dialogRef.close({ success: true });
  }

}
