<nav class="navbar administration-header body-section justify-content-end" *ngIf="!isSalesForceFlow">
  <div class="navbar-expand-lg p-0">
    <ul class="navbar-nav">
      <ng-container *ngFor="let menuItem of data['menuItems']">
        <!-- With Submenus -->
        <ng-container *ngIf="menuItem['subMenus'].length > 0; else noSubMenus">
          <li class="nav-item dropdown ml-3">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ menuItem["name"] }}
              <img alt="" class="arrow-down" src="../../../assets/images/icons/arrow-down.svg" />
          </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <ng-container *ngFor="let submenu of menuItem['subMenus']">
                <li *ngIf="submenu['subMenus'].length > 0; else elseBlock1">
                  <button class="dropdown-item">{{
                    submenu["name"]
                  }}</button>
                </li>
                <ng-template #elseBlock1>
                  <li>
                    <button class="dropdown-item" [routerLink]="[submenu['routerLink']]">{{ submenu["name"] }}</button>
                  </li>
                </ng-template>
                <ng-container *ngIf="submenu['subMenus'].length > 0">
                  <ng-container *ngFor="let submenu1 of submenu['subMenus']">
                    <li>
                      <button class="dropdown-item" [routerLink]="[submenu1['routerLink']]" style="font-size: 0.9rem;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{ submenu1["name"] }}
                      </button>
                    </li>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </li>
        </ng-container>
        <!-- With Submenus / -->

        <!-- Without Submenu -->
        <ng-template #noSubMenus>
          <li class="nav-item ml-3">
            <button class="nav-link" >{{ menuItem["name"] }}</button>
          </li>
        </ng-template>
        <!-- Without Submenu / -->
      </ng-container>

      <!-- User -->
      <li class="nav-item dropdown ml-3" *ngIf="this.userName !== ''">
        <a href="#" class="nav-link dropdown-toggle text-capitalize" data-toggle="dropdown">{{ this.userName }}
          <img alt="" class="arrow-down" src="../../../assets/images/icons/arrow-down.svg" />
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" (click)="openUserProfile()">Profile</button>
          <!-- <button class="dropdown-item" (click)="changePassword()">Change Password</button> -->
          <button class="dropdown-item" (click)="logout()">Logout</button>
        </div>
      </li>
      <!-- User / -->
    </ul>
  </div>
</nav>