import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CartService, CpqAccount, CpqObjects } from '@cpq-app/services/cart.service';
import { ProductService } from '@cpq-app/services/product.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-open-opportunity',
  templateUrl: './open-opportunity.component.html',
  styleUrls: ['./open-opportunity.component.scss']
})
export class OpenOpportunityComponent {
  saveProjectForm: FormGroup;
  subscription$: Subscription[] = [];  
  availableAccounts = [];
  submitted = false;
  LOGIN_SPINNER = 'login';
  opportunityId: string;
  revisionId: string;
  configId: string;
  productsData: any;
  searchString = ' ';
  startNewProjectFlow: boolean;
  projectPayload: any[] = [];
  opportunities: any[] = [];
  @Input() opportunity: any;
  isUpdateProject: boolean;

  filteredAccounts: Observable<any[]>;
  selectedAccount;
  isAmbientTemperatureAvailable: boolean = false;
  openInfoWindowsArray = [];

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private cartService: CartService,
    private productService: ProductService,
  ) { }
  ngOnInit(): void {
    this.initialFormData();
    this.getAccountNames();
    if (this.opportunity) {
      this.opportunity = JSON.parse(this.opportunity);
      this.opportunityId = this.opportunity.Id;
      this.selectedAccount = this.opportunity?.Account;
      this.saveProjectForm.controls.accountSelection.setValue(this.opportunity?.Account)
      this.isUpdateProject = true;
      this.editFormInitialData();
    }
  }

  displayFn(account?: any): string | undefined {
    return account ? account.Name : undefined;
  }
  returnFn(account?: any): string | undefined {
    return account ? account.Id : undefined;
  }

  private _filter(Name: string): any[] {
    const filterValue = Name.toLowerCase();

    return this.availableAccounts.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  compareObjects(obj1: CpqAccount, obj2: CpqAccount): boolean {
    return obj1?.Id === obj2?.Id;
  }


  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    const zipcodePattern = '^[0-9]{5}(?:-[0-9]{4})?$';
    this.saveProjectForm = this.formBuilder.group({
      accountSelection: [null, [Validators.required]],
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]]
    });
  }

  editFormInitialData() {
    this.opportunity = this.opportunity;
    // this.selectedAccount = this.opportunity?.Account?.Name;
    this.saveProjectForm.controls.Name.setValue(this.opportunity?.Name);

    // this.spinner.show();
    // this.subscription$.push(this.cartService.getCpqObjects(CpqObjects.Opportunity, this.opportunityId)
    //   .subscribe({
    //     next: (opportunity: any) => {
    //       this.opportunity = opportunity;
    //       this.selectedAccount = opportunity?.Account?.Name;
    //       this.saveProjectForm.controls.Name.setValue(opportunity?.Name);
    //     },
    //     error: err => {
    //       this.spinner.show();
    //       this.toastr.error(
    //         'There is fatal error while fetching Project details', 'Error', {
    //         disableTimeOut: true,
    //         closeButton: true
    //       }
    //       );
    //     },
    //     complete: () =>{ this.spinner.hide()}
    //   }));
  }

  getAccountNames() {
    this.subscription$.push(this.cartService.getCpqObjects<any>(CpqObjects.Accounts)
      .subscribe(
        (results) => {
          const accountsWithExternalId = results.filter(account => account.ExternalId);
          this.availableAccounts = [...this.availableAccounts, ...accountsWithExternalId];
          this.selectedAccount = this.opportunity?.Account;
          this.saveProjectForm.controls.accountSelection.setValue(this.opportunity?.Account)
          // this.selectedAccount = this.opportunity?.Account?.Name;
          // this.filteredAccounts = this.saveProjectForm.controls['accountSelection'].valueChanges.pipe(
          //   startWith(""),
          //   map(value => (typeof value === "string" ? value : value.Name)),
          //   map(name => (name ? this._filter(name) : this.availableAccounts.slice()))
          // );
        },
        err => {
          this.toastr.error(
            'There is fatal error while fetching Account Names', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      ));
  }

  onProjectSave() {
    let account = this.saveProjectForm.value.accountSelection ? this.saveProjectForm.value.accountSelection : '';
    this.spinner.show();
    if (account) {
      this.cartService.updateObjectById(CpqObjects.Opportunity, this.opportunityId, {
        AccountId: account?.Id,
        Name: this.saveProjectForm.value.Name,
      }).subscribe(data => {
        this.cartService.updateCart.next(account?.Id);
        this.modal.dismiss();
      });
    } else {
      this.toastr.error(
        'Please select valid sold to customer.'
      );
    }
    this.spinner.hide();

  }

  prepareProjectPayload() {
    if (!this.saveProjectForm.invalid) {
      let payload = {};
      return payload = {
        'Name': this.saveProjectForm.controls.Name.value || 'DFRCO2 Project',
        'Ambient_Temp__c': this.saveProjectForm.controls.Ambient_Temp__c.value,
        'Zip_Code__c': this.saveProjectForm.controls.Zip_Code__c.value,
        'application__c': this.saveProjectForm.controls.application__c.value,
        'storeType__c': this.saveProjectForm.controls.storeType__c.value,
        'accountSelection': this.saveProjectForm.value.accountSelection?.Id,
        'drop_ship_address_line_1__c': this.saveProjectForm.controls.Zip_Code__d?.value,
        'latitude__c': this.saveProjectForm.controls.latitude__c?.value,
        'longitude__c': this.saveProjectForm.controls.longitude__c?.value,
        'AmbientTempJSON__c': JSON.stringify(this.saveProjectForm.controls.AmbientTempJSON__c.value),
        'stationName__c': this.saveProjectForm.controls.stationName__c?.value
      };
    }
  }

  getControlValue(type: string) {
    return this.saveProjectForm.controls[type].value;
  }


  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
