import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { environment } from '@cpq-environments/environment';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

interface CpqLoginResult {
    success?: boolean;
    authenticated?: boolean;
    id?: string;
    rfst?: string;
    userId: string;
    Username?: string;
}

export enum CpqObjectType {
    Quote = 'quote',
    Opportunity = 'opportunity',
    Account = 'account',
    Partner = 'partner',
    Role = 'role',
    User = 'user',
    Profile = 'profile',
    Favorite = 'favorite',
    QuoteProduct = 'quoteproduct',
    Product = 'quoteproduct',
    QuoteLine = 'quoteline',
}

interface CpqUser {
    Id: string;
    Username: string;
    FirstName: string;
    LastName: string;
    IsActive: boolean;
    ProfileId: string;
    UserRoleId?: string;
    PartnerId?: string;
    IsSharedAnonymous: boolean;
    IsTemplateAnonymous: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    // Local Storage symbols
    readonly RFST = 'rfstToken';
    readonly USER_ID = 'userId';
    readonly USERNAME = 'username';
    private backendUrl = environment.B2CConfigs.BackendURL;

    oppurtunityId: string;
    quoteId: string;
    correlationId: string;
    configId: string;

    private loginSuccess = new Subject();
    loginSuccess$ = this.loginSuccess.asObservable();

    constructor(
        private http: HttpClient,
        private guestService: GuestConfigService,
    ) { }

    store(key, value) {
        sessionStorage.setItem(key, value);
    }
    retrive(key) {
        return sessionStorage.getItem(key);
    }
    remove(key) {
        sessionStorage.removeItem(key);
    }

    // /**
    //  * Observable that return login details
    //  * If session exists, it return login details along with session rfst.
    //  * If no session, it creates new session and return login details.
    //  */
    // loginUser(): Observable<CpqLoginResult> {
    //     return new Observable<any>(observer => {
    //         const validateSub = this.validateSession().pipe(
    //             switchMap(isValid => {
    //                 if (isValid) {
    //                     return this.getLoginInfo();
    //                 }
    //                 return this.requestNewSession();
    //             })
    //         ).subscribe(login => {
    //             if (login.rfst) {
    //                 this.store(this.RFST, login.rfst);
    //             }
    //             if (login.userId) {
    //                 this.store(this.USER_ID, login.userId);
    //             }
    //             this.updateUsername();
    //             observer.next(login);
    //             observer.complete();

    //         }, error => {
    //             observer.error(error);
    //             observer.complete();
    //         });

    //         return {
    //             unsubscribe: () => {
    //                 validateSub?.unsubscribe();
    //             },
    //         };

    //     });
    // }

    updateLoginUserInfo(login: CpqLoginResult) {
        if (login.rfst) {
            this.store(this.RFST, login.rfst);
        }
        if (login.id) {
            this.store(this.USER_ID, login.id);
        }
        if (login?.Username) {
            this.store(this.USERNAME, login.Username);
        }
    }

        /**
     * Observable that return login details
     * If session exists, it return login details along with session rfst.
     * If no session, it creates new session and return login details.
     */
        loginUser(): Observable<CpqLoginResult> {
            return new Observable(observer => {
                const subscription = this.validateSession().subscribe(
                    loginInfo => {
                        this.updateLoginUserInfo(loginInfo);
                        observer.next(loginInfo);
                        observer.complete();
                    }
                );
                return {
                    unsubscribe: () => {
                        subscription.unsubscribe();
                    }
                };
            });
        }


        /**
     * Validate the current session by checking if the corId is present and
     * matches the singleton's existing corId. If true, contact the server and verify
     * that the JSESSION is still good.
     * @param corId a `string` of the correlation id
     */
        private validateSession(corId?: string): Observable<CpqLoginResult> {
            return new Observable<any>(observer => {
                if (corId && (this.correlationId !== corId)) {
                    observer.next(false);
                    observer.complete();
                }
    
                const url = this.cpqUrl('session');
                const getSub = this.http
                    .get<any>(url, { withCredentials: true })
                    .subscribe(response => {
                        //console.log(`%cValidate:%c Status is ${response.ok}`, 'background-color: green', 'background-color:white');
                        // If the response is OK, then the session is valid
                        sessionStorage.setItem('userRole', response.userRole);
                        this.guestService.setGuestUserDetails(response);
                        observer.next(response);
                        observer.complete();
                    }, error => {
                        console.log(`%cValidate:%c Status is errored`, 'background-color: red', 'background-color:white');
                        observer.next(error);
                        observer.complete();
                    });
    
                return {
                    unsubscribe: () => {
                        getSub?.unsubscribe();
                    }
                };
            });
        }
    

    
    /**
     * Refreshes the RFST via a token exchange
     * @returns `Observable` of the RFST as `string`
     */
    renewRfst(): Observable<string> {
        return this.fetchLoginAuthToken().pipe(
            switchMap(data => this.loginWithAuthToken(data.token)),
            map<CpqLoginResult, string>(res => {
                if (res.rfst) {
                    this.store(this.RFST, res.rfst);
                    return res.rfst;
                } else {
                    this.store(this.RFST, undefined);
                    throwError('RFST could not be renewed');
                }
            }, err => {
                this.store(this.RFST, undefined);
                throw err;
            }),
        );
    }

    private fetchLoginAuthToken(): Observable<any> {
        const url = this.cpqUrl('cpq', 'logintoken');
        return this.http.get(url, { withCredentials: true });
    }

    private loginWithAuthToken(token: string): Observable<CpqLoginResult> {
        const url = this.cpqUrl('cpq', 'login');
        const httpOptions = {
            headers: new HttpHeaders({
                'fpx-auth-token': token
            }),
            withCredentials: true,
        };
        return this.http.post<CpqLoginResult>(url, {
            username: this.retrive(this.USERNAME),
        }, httpOptions);
    }



    /**
     * Method to logout from the CPQ session
     */
    logout(): Observable<boolean> {
        const url = this.cpqUrl('cpq', 'logout');
        this.store(this.RFST, undefined);

        return this.http.post(url, null, { observe: 'response', withCredentials: true }).pipe(
            map<HttpResponse<any>, boolean>(res => !!res?.ok),
            catchError(err => {
                console.error('Failed to logout of CPQ', err);
                return of(false);
            })
        );
    }

    getRfst(): Observable<string> {
        const rfst = this.retrive(this.RFST);

        if (!rfst || (rfst.trim().length < 1)) {
            return this.renewRfst();
        }

        return of(rfst);
    }

    // getObject<T>(objectId: string): Observable<T> {
    //     const url = this.cpqUrl('cpq', objectId);
    //     return this.http.get<T>(url, { observe: 'body', withCredentials: true });
    // }

    getObject<T>(objectType: CpqObjectType, objectId: string): Observable<T> {
        const url = this.cpqUrl('object', objectType, objectId);
        return this.http.get<T>(url, { observe: 'body', withCredentials: true });
    }

    // cpqUrl(...args: string[]): string {
    //     let url = `${this.backendUrl}/cpq/Proxy?path=/rs/19`;

    //     // tslint:disable-next-line: prefer-for-of
    //     for (let i = 0; i < args.length; i++) {
    //         if (args[i] != null) {
    //             // Do not append null or undefined; doesn't stop empty strings
    //             url += '/' + args[i];
    //         }
    //     }

    //     return url;
    // }
    cpqUrl(...args: string[]): string {
        let url = `${this.backendUrl}/cpq`;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < args.length; i++) {
            if (args[i] != null) {
                // Do not append null or undefined; doesn't stop empty strings
                url += '/' + args[i];
            }
        }

        return url;
    }

}
