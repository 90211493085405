import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/services/authguard.service';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

/*======================================== CPQ Related =================================*/
import { LandingPageComponent } from '../app/content/landing-page/landing-page.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordSuccessComponent } from './changePasswordSuccess/changePasswordSuccess.component';
import { CxCpqSsoComponent } from './cx-cpq-sso/cx-cpq-sso.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'opportunity/:sfdcOpportunityId',
    component: LandingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cx-cpq-auth',
    component: CxCpqSsoComponent
  },
  {
    path: 'cx-cpq/logout',
    component: LogoutComponent,
  },
  {
    path: 'privacy-terms',
    component: PrivacyTermsComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'products',
    loadChildren: () =>
      import('../app/content/product/product.module').then(
        (p) => p.ProductModule
      ),
  },
  {
    path: 'setup',
    loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./adminstration/adminstration.module').then((m) => m.AdminModule),
  },
  {
    path: 'changePasswordSuccess',
    component: ChangePasswordSuccessComponent
  },
  {
    path: '**',
    component: PagenotfoundComponent,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })]
})
export class AppRoutingModule { }
