<div class="modal-header mt-2">
    <h4 class="modal-title main-header pull-left"> {{type=='create' ? 'CREATE QUOTE' : 'EDIT QUOTE'}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="project-details modal-body">
    <div class="row">
        <ngx-spinner [fullScreen]="false" type="cog" size="large" [name]="LOGIN_SPINNER">
            <p class="login-spinner">Getting things ready...</p>
        </ngx-spinner>
        <div class="col-md-12">
            <form class="pt-3 mr-3 ml-3" [formGroup]="quoteForm">
                <div class=" child-control">
                    <label for="Name" class="form-labels f-label">Quote Name<span
                            class="required-text">(Required)</span></label>
                    <input type="text" class="form-control" id="Name" formControlName="Name"
                        [ngClass]="{ 'is-invalid':  (quoteForm.controls['Name'].dirty || quoteForm.controls['Name'].touched ) && quoteForm.controls['Name'].errors }">
                    <div *ngIf="quoteForm.controls['Name'].invalid && (quoteForm.controls['Name'].dirty || quoteForm.controls['Name'].touched)"
                        class="invalid-feedback">
                        <div *ngIf="quoteForm.controls['Name'].errors?.required">
                            Project Name is required</div>
                        <div *ngIf="quoteForm.controls['Name'].errors?.pattern">
                            Project Name must be a character</div>
                        <div *ngIf="quoteForm.controls['Name'].errors?.maxlength">Project Name
                            must
                            contain max of
                            120
                            characters</div>
                    </div>
                </div>

                <div class="mt-3 child-control">
                    <label for="Note" class="form-labels f-label">Note</label>
                    <textarea type="text" class="form-control" id="Note" formControlName="Note" rows="1"
                        [ngClass]="{ 'is-invalid':  (quoteForm.controls['Note'].dirty || quoteForm.controls['Note'].touched ) && quoteForm.controls['Name'].errors }">
                </textarea>
                    <div *ngIf="quoteForm.controls['Note'].invalid && (quoteForm.controls['Note'].dirty || quoteForm.controls['Note'].touched)"
                        class="invalid-feedback">
                        <div *ngIf="quoteForm.controls['Note'].errors?.required">
                            Project Name is required</div>
                        <div *ngIf="quoteForm.controls['Note'].errors?.pattern">
                            Project Name must be a character</div>
                        <div *ngIf="quoteForm.controls['Note'].errors?.maxlength">Project Name
                            must
                            contain max of
                            120
                            characters</div>
                    </div>
                    <div *ngIf="type!=='create'" class="mt-3">
                        <label>Primary Quote</label>
                        <div><input class="ml-3" type="checkbox" formControlName="IsPrimary"></div>                       
                    </div>
                </div>
                <div class="modal-footer d-flex">
                    <button mat-flat-button color="accent" type="button" class="cpq-button-text btn-submit-quote"
                        (click)="modal.dismiss()">Cancel</button>
                    <span><button mat-flat-button color="accent" type="button" class="cpq-button-text btn-submit-quote"
                            [disabled]="quoteForm.invalid || !quoteForm.dirty"
                            (click)="updateDetails()">{{type=='create' ? 'Create' : 'Update'}}</button></span>
                </div>
            </form>
        </div>
    </div>
</div>