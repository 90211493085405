import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { MSALService } from 'src/app/shared/services/msal.service';
import { CartService } from '@cpq-app/services/cart.service';
import { forkJoin, of } from 'rxjs';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent implements OnChanges, OnInit {
  @Input() data;
  @Input() userNameData;
  userName = '';
  personaName = '';
  userType = '';
  isSalesForceFlow = false;
  
  constructor(
    private router: Router,
    private authService: MSALService,
    private cartService: CartService,
    private logoutService: LogoutService,
    private toastr: ToastrService
  ) { }
  ngOnInit() {
    this.isSalesForceFlow = sessionStorage.getItem('salesforceToken') ? true : false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data?.firstChange) {
      if (!changes.data?.currentValue['user']) {
        this.userName = 'Admin';
        this.personaName = 'Super Admin';
      } else {
        this.personaName = changes.data?.currentValue['user']['role'];
      }
      this.userType = changes.data?.currentValue['user']['userType'];
    }
    if (!changes.userNameData?.firstChange) {
      this.userName = changes.userNameData.currentValue;
    }
  }

  logout() {
    this.logoutService.logout();
  }

  openUserProfile() {
    if (this.userType !== 'superadmin') {
      this.router.navigate(['/profile']);
    } else {
      this.toastr.info('Profile is not available for super admin.');
    }
  }

  // changePassword(){
  //   this.authService.callChangePasswordPolicy();
  // }
}
